<template>
  <div>
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            ตั้งค่าระบบ
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3" v-if="$isLoading">
        <b-row class="pl-1">
          <b-col cols="12 d-flex justify-content-end">
            <div class="panel d-flex align-items-md-center">
              <b-form-checkbox
                size="lg"
                id="sameLang"
                class="mt-0"
                @change="useSameLanguage"
                v-model="form.isSameLanguage"
                >ใช้เหมือนกันทุกภาษา
              </b-form-checkbox>
            </div>
            <div class="panel">
              <b-button
                type="button"
                class="btn btn-language"
                v-for="(language, index) in languageList"
                v-bind:key="index"
                v-bind:class="[languageActive == language.id ? 'active' : '']"
                @click="changeLanguage(language.id, index)"
                :disabled="form.isSameLanguage ? true : false"
              >
                <span class="text-uppercase">{{ language.nation }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <label class="ml-3 font-weight-bold">Images in Link Shares</label>
        </b-row>
        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="Image"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="all"
              name="thumbnail"
              :fileName="fileNameIconFacebook"
              text="*กรุณาอัพโหลดไฟล์สกุล .png ขนาด 1 : 1  ขนาดไม่เกิน 8 MB"
              isRequired
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              :v="$v.fileNameIconFacebook"
            />
            <div
              class="preview-box ratio-10-2-pb image"
              v-if="coverImgType == 1"
              v-bind:style="{ 'background-image': 'url(' + showPreview + ')' }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </div>
            <div
              class="preview-box position-relative p-0 embed-responsive embed-ratio-10-2-pb"
              v-else
            >
              <video ref="videoRef" class="w-100 video-box" controls>
                <source :src="showPreview" type="video/mp4" />
              </video>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="Image for Facebook"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="all"
              name="thumbnail"
              :fileName="fileNameIcon"
              text="*กรุณาอัพโหลดไฟล์สกุล .png ขนาด 1,200 x 630 PX ขนาดไม่เกิน 8 MB"
              isRequired
              v-on:onFileChange="onMobileImageChange"
              v-on:delete="deleteMobileImage"
              :v="$v.fileNameIcon"
            />
            <div
              class="preview-box ratio-10-4-pb image-facebook"
              v-if="mobileCoverImgType == 1"
              v-bind:style="{
                'background-image': 'url(' + mobileShowPreview + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingMobileImage"
              />
            </div>
            <div
              class="preview-box position-relative p-0 embed-responsive embed-ratio-10-4-pb"
              v-else
            >
              <video ref="videoMobileRef" class="w-100 video-box" controls>
                <source :src="mobileShowPreview" type="video/mp4" />
              </video>
            </div>
          </b-col>
        </b-row>
        <b-row class="p-3">
          <b-button v-b-toggle.collapse-seo class="collapse-btn">
            SEO
            <font-awesome-icon
              icon="chevron-right"
              class="icon float-right mt-1"
            />
            <font-awesome-icon
              icon="chevron-down"
              class="icon float-right mt-1"
            />
          </b-button>
          <b-collapse id="collapse-seo" class="w-100" visible>
            <b-card class="card-box card-seo w-100">
              <div v-for="(item, index) in form.metaSeo" v-bind:key="index">
                <div
                  v-bind:class="[
                    languageActive == item.languageId ? '' : 'd-none',
                  ]"
                >
                  <b-row>
                    <b-col>
                      <InputText
                        textFloat="Meta Keyword"
                        placeholder="Meta Keyword"
                        type="text"
                        name="keyword"
                        v-model="item.keyword"
                        isRequired
                        :img="imageLogoLang"
                        :isValidate="
                          $v.form.metaSeo.$each.$iter[index].keyword.$error
                        "
                        :v="$v.form.metaSeo.$each.$iter[index].keyword"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <InputText
                        textFloat="Meta Description"
                        placeholder="Meta Description"
                        type="text"
                        name="metadesc"
                        v-model="item.description"
                        isRequired
                        :img="imageLogoLang"
                        :isValidate="
                          $v.form.metaSeo.$each.$iter[index].description.$error
                        "
                        :v="$v.form.metaSeo.$each.$iter[index].description"
                      />
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-card>
          </b-collapse>
        </b-row>
        <b-row class="mt-0">
          <b-col md="12" class="text-sm-right">
            <button
              type="button"
              @click="checkForm()"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

export default {
  name: "Setting",
  components: {
    InputText,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      isLoadingImage: false,
      isLoadingMobileImage: false,
      coverImgType: 1,
      showPreview: "",
      mobileCoverImgType: 1,
      mobileShowPreview: "",
      images: "",
      mobileImages: "",
      modalMessage: "",
      isDisable: false,
      fileNameIconFacebook: "",
      fileNameIcon: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      form: {
        favIcon: "",
        favIconFacebook: "",
        metaSeo: [
          {
            languageId: "",
            keyword: "",
            description: "",
          },
          {
            languageId: "",
            keyword: "",
            description: "",
          },
        ],
      },
      imgTypeId: 8,
    };
  },
  validations: {
    fileNameIconFacebook: { required },
    fileNameIcon: { required },
    form: {
      metaSeo: {
        $each: {
          keyword: { required },
          description: { required },
        },
      },
    },
  },
  created: async function () {
    await this.getData();
    await this.changeLanguage(1, 0);
    this.$isLoading = true;
  },
  methods: {
    getData: async function () {
      this.$isLoading = false;
      let languages = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/language`,
        null,
        this.$headers,
        null
      );
      if (languages.result == 1) {
        this.languageList = languages.detail;
      }
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Setting/GetSeo`,
        null,
        this.$headers,
        null
      );
      if (data) {
        this.form = data.detail;
        this.mobileShowPreview = this.form.favIcon;
        this.fileNameIcon = this.form.favIcon;
        this.showPreview = this.form.favIconFacebook;
        this.fileNameIconFacebook = this.form.favIconFacebook;
        this.$v.form.$reset();
      }
      this.$isLoading = true;
    },
    async changeLanguage(id, index) {
      if (!this.form.isSameLanguage) {
        this.languageActive = id;
        this.imageLogoLang = this.languageList[index].imageUrl;
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.images = reader.result;
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = this.images;
        this.form.favIcon = this.images;
        this.fileNameIconFacebook = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.favIcon = null;
      this.fileNameIconFacebook = "";
      this.showPreview = null;
    },
    onMobileImageChange(img) {
      this.isLoadingMobileImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.mobileImages = reader.result;
        this.mobileImages = await this.saveImagetoDb(reader.result);
        this.isLoadingMobileImage = false;
        this.isDisable = false;
        this.mobileShowPreview = this.mobileImages;
        this.form.favIconFacebook = this.mobileImages;
        this.fileNameIcon = this.mobileImages;
      };
    },
    deleteMobileImage() {
      this.form.favIconFacebook = null;
      this.fileNameIcon = "";
      this.mobileShowPreview = null;
    },
    checkForm: async function () {
      if (this.form.isSameLanguage) {
        await this.useSameLanguage();
      }
      this.$v.$touch();
      if (this.$v.$error) {
        await this.checkValidateTranslationList();
        return;
      }
      await this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Setting/saveSeo`,
        null,
        this.$headers,
        this.form
      );
      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();

      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        await this.getData();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    useSameLanguage: async function () {
      Vue.nextTick(() => {
        if (this.form.isSameLanguage) {
          this.imageLogoLang = "";
          let data = this.form.metaSeo.filter(
            (val) => val.languageId == this.languageActive
          );
          if (data.length == 1) {
            data = data[0];
            for (let index = 0; index < this.form.metaSeo.length; index++) {
              this.form.metaSeo[index].keyword = data.keyword;
              this.form.metaSeo[index].description = data.description;
            }
          }
        } else {
          var index = this.languageList
            .map(function (x) {
              return x.id;
            })
            .indexOf(this.languageActive);
          this.imageLogoLang = this.languageList[index].imageUrl;

          let data = this.form.metaSeo.filter(
            (val) => val.languageId == this.languageActive
          );
          if (data.length == 1) {
            data = data[0];
            data.keyword = "";
            data.description = "";
          }
        }
      });
    },
    checkValidateTranslationList: async function () {
      let isError = false;
      this.languageList.forEach((element, index) => {
        if (!isError) {
          if (this.$v.form.metaSeo.$each.$iter[index].$error) {
            this.languageActive = this.$v.form.metaSeo.$model[index].languageId;
            this.imageLogoLang = this.languageList[index].imageUrl;
            isError = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}

.image {
  width: 50% !important;
  padding-bottom: 50% !important;
}

.image-facebook {
  padding-bottom: 60% !important;
}
</style>
