<script>
export default {
  name: "nav",
  data() {
    return {
      permissionList: [],
      sidebar: {
        _name: "CSidebarNav",
        _children: [
          {
            id: 1,
            _name: "CSidebarNavDropdown",
            name: `ภาพรวม`,
            icon: "cil-bar-chart",
            permission: 1,
            _children: [
              // {
              //   _name: "CSidebarNavItem",
              //   name: `ภาพรวมสินค้า`,
              //   to: "/dashboard/productoverview",
              //   permission: 1,
              //   icon: "cil-bar-chart",
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: `รายได้สินค้าทั้งหมด`,
              //   to: "/dashboard/revenueproduct",
              //   permission: 1,
              //   icon: "cil-bar-chart",
              // },
              {
                _name: "CSidebarNavItem",
                name: `ภาพรวมการขาย`,
                to: "/dashboard/performance",
                permission: 79,
                icon: "cil-bar-chart",
              },
              {
                _name: "CSidebarNavItem",
                name: `ประสิทธิภาพของสินค้า`,
                to: "/dashboard/rankproduct",
                permission: 80,
                icon: "cil-bar-chart",
              },
              {
                _name: "CSidebarNavItem",
                name: `ภาพรวมโปรโมชั่น`,
                to: "/dashboard/promotion",
                permission: 81,
                icon: "cil-bar-chart",
              },
              // {
              //   _name: "CSidebarNavItem",
              //   name: `แฟรชเซล`,
              //   to: "/dashboard/campaign",
              //   permission: 1,
              //   icon: "cil-bar-chart",
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: `ภาพรวมของสินค้าทั้งหมด`,
              //   to: "/dashboard/productperformance",
              //   permission: 1,
              //   icon: "cil-bar-chart",
              // },
            ],
          },
          {
            id: 75,
            _name: "CSidebarNavDropdown",
            name: "คำสั่งซื้อ",
            icon: "cil-cart",
            permission: 75,
            _children: [
              {
                id: 1,
                _name: "CSidebarNavItem",
                name: "คำสั่งซื้อทั้งหมด",
                to: "/order",
                icon: "cil-cart",
                permission: 29,
              },
              // {
              //   id: 1,
              //   _name: "CSidebarNavItem",
              //   name: "รีเซนออเดอร์",
              //   to: "/resendorder",
              //   icon: "cil-cart",
              //   permission: 30,
              // },
              {
                _name: "CSidebarNavItem",
                name: "รอยืนยันการชำระเงิน",
                to: "/order/verify",
                icon: "cil-cart",
                permission: 31,
              },
              {
                _name: "CSidebarNavItem",
                name: "รายการคืนสินค้า",
                to: "/return",
                icon: "cil-cart",
                permission: 32,
              },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการที่ต้องโอนจ่าย",
              //   to: "/refund",
              //   icon: "cil-cart",
              //   permission: 53,
              // },
            ],
          },
          {
            id: 76,
            _name: "CSidebarNavDropdown",
            name: "ลูกค้า",
            icon: "cil-people",
            permission: 76,
            _children: [
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการลูกค้าบริษัท",
              //   to: "/affiliate",
              //   icon: "cil-people",
              //   permission: 38,
              // },
              {
                _name: "CSidebarNavItem",
                name: "รายการลูกค้าทั่วไป",
                to: "/member",
                icon: "cil-people",
                permission: 39,
              },
            ],
          },
          {
            id: 77,
            _name: "CSidebarNavDropdown",
            name: "ตั้งค่าการขาย",
            icon: "cil-cart",
            permission: 77,
            _children: [
              {
                id: 1,
                _name: "CSidebarNavItem",
                name: "สินค้า",
                to: "/product",
                icon: "cil-gift",
                permission: 28,
              },
              {
                _name: "CSidebarNavItem",
                name: "ราคาพิเศษ",
                to: "/product-special",
                icon: "cil-gift",
                permission: 29,
              },
              {
                id: 1,
                _name: "CSidebarNavItem",
                name: "โปรโมชั่น",
                to: "/promotion",
                icon: "cil-gift",
                permission: 78,
              },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการเงื่อนไขขนส่ง",
              //   to: "/shipping",
              //   icon: "cil-list",
              //   permission: 28,
              // },
            ],
          },
          {
            id: 2,
            _name: "CSidebarNavDropdown",
            name: "ตั้งค่าเว็บไซต์",
            icon: "cil-laptop",
            permission: 2,
            _children: [
              // {
              //   _name: "CSidebarNavItem",
              //   name: "ป๊อปอัพหน้าเว็บไซต์",
              //   to: "/banner-popup",
              //   icon: "cil-image",
              //   permission: 82,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "หน้าหลัก - ตั้งค่า",
              //   to: "/setting-home",
              //   icon: "cil-image",
              //   permission: 84,
              // },
              {
                _name: "CSidebarNavItem",
                name: "หน้าหลัก - แบนเนอร์",
                to: "/banner",
                icon: "cil-image",
                permission: 3,
              },
              {
                _name: "CSidebarNavItem",
                name: "หน้าหลัก - สินค้าโปรโมชั่น",
                to: "/product-promotion",
                icon: "cil-gift",
                permission: 64,
              },
              {
                _name: "CSidebarNavItem",
                name: "หน้าหลัก - สินค้าแนะนำ",
                to: "/product-recommend",
                icon: "cil-gift",
                permission: 65,
              },
              {
                _name: "CSidebarNavItem",
                name: "หน้าหลัก - สินค้าไฮไลท์",
                to: "/product-highlight",
                icon: "cil-gift",
                permission: 66,
              },
              {
                _name: "CSidebarNavItem",
                name: "แบนเนอร์เซ็คชั่น",
                to: "/section-banner",
                icon: "cil-image",
                permission: 50,
              },
              {
                _name: "CSidebarNavItem",
                name: "Custom Image",
                to: "/custom-image",
                icon: "cil-image",
                permission: 50,
              },
              {
                _name: "CSidebarNavItem",
                name: "แบนเนอร์สินค้า",
                to: "/product/banner",
                icon: "cil-image",
                permission: 50,
              },
              {
                _name: "CSidebarNavItem",
                name: "แบนเนอร์บทความ",
                to: "/news/banner",
                icon: "cil-image",
                permission: 51,
              },
              {
                _name: "CSidebarNavItem",
                name: "แบนเนอร์ติดต่อเรา",
                to: "/contactus/banner",
                icon: "cil-image",
                permission: 51,
              },
              {
                _name: "CSidebarNavItem",
                name: "แบนเนอร์คำถามที่พบบ่อย",
                to: "/faq/banner",
                icon: "cil-image",
                permission: 51,
              },
              {
                _name: "CSidebarNavItem",
                name: "หมวดหมู่",
                to: "/category",
                icon: "cil-list",
                permission: 50,
              },
              {
                _name: "CSidebarNavItem",
                name: "หมวดหมู่ป้ายสินค้า",
                to: "/category/tags",
                icon: "cil-list",
                permission: 67,
              },
              {
                _name: "CSidebarNavItem",
                name: "หมวดหมู่สินค้ากลุ่ม",
                to: "/category/productgroup",
                icon: "cil-list",
                permission: 68,
              },
              {
                to: "/category/imagelist",
                name: "จัดการรูปหมวดหมู่",
                _name: "CSidebarNavItem",
                icon: "cil-image",
                permission: 69,
              },
              {
                _name: "CSidebarNavItem",
                name: "โลโก้",
                to: "/logo",
                icon: "cil-image",
                permission: 4,
              },
              {
                _name: "CSidebarNavItem",
                name: "โลโก้ Footer",
                to: "/logo-footer",
                icon: "cil-image",
                permission: 70,
              },
              {
                id: 1,
                _name: "CSidebarNavItem",
                name: "บทความ",
                to: "/news",
                icon: "cil-newspaper",
                permission: 8,
              },
              {
                _name: "CSidebarNavItem",
                name: "หมวดหมู่บทความ",
                to: "/category/news",
                icon: "cil-list",
                permission: 71,
              },
              {
                _name: "CSidebarNavItem",
                name: "ติดต่อเรา",
                to: "/contactus",
                icon: "cil-list",
                permission: 5,
              },
              {
                _name: "CSidebarNavItem",
                name: "นโยบายคุกกี้",
                to: "/cookie-policy",
                icon: "cil-file",
                permission: 72,
              },
              {
                _name: "CSidebarNavItem",
                name: "นโยบายการคืนสินค้า",
                to: "/return-policy",
                icon: "cil-file",
                permission: 73,
              },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "จัดการแบนเนอร์ Live",
              //   to: "/live/banner",
              //   icon: "cil-image",
              //   permission: 52,
              // },

              // {
              //   _name: "CSidebarNavItem",
              //   name: "จัดการภาพ Landing page",
              //   to: "/landingpage",
              //   icon: "cil-image",
              //   permission: 59,
              // },
              // {
              //   id: 1,
              //   _name: "CSidebarNavItem",
              //   name: "เรื่องราวของเรา",
              //   to: "/our-story",
              //   icon: "cil-list",
              //   permission: 5,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการโฆษณา",
              //   to: "/ads",
              //   icon: "cil-image",
              //   permission: 6,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการรีวิว",
              //   to: "/review",
              //   icon: "cil-comment-bubble",
              //   permission: 7,
              // },

              // {
              //   id: 1,
              //   _name: "CSidebarNavItem",
              //   name: "เงื่อนไขการใช้งานผ่านระบบไลฟ์",
              //   to: "/how-to-use",
              //   icon: "cil-file",
              //   permission: 54,
              // },

              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการดีล",
              //   to: "/bestdeal",
              //   icon: "cil-home",
              //   permission: 19,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการแบรนด์ของเรา",
              //   to: "/our-partner",
              //   icon: "cil-people",
              //   permission: 20,
              // },

              // {
              //   id: 2,
              //   _name: "CSidebarNavDropdown",
              //   name: "เกี่ยวกับอะคาเดมี่",
              //   icon: "cil-image",
              //   permission: 22,
              //   _children: [
              //     {
              //       _name: "CSidebarNavItem",
              //       name: "รายการแบนเนอร์อะคาเดมี่",
              //       to: "/academy-banner",
              //       icon: "cil-image",
              //       permission: 22,
              //     },
              //     {
              //       _name: "CSidebarNavItem",
              //       name: "อะคาเดมี่ 1",
              //       to: "/academy/academy-01",
              //       icon: "cil-file",
              //       permission: 23,
              //     },
              //     {
              //       _name: "CSidebarNavItem",
              //       name: "อะคาเดมี่ 2",
              //       to: "/academy/academy-02",
              //       icon: "cil-file",
              //       permission: 24,
              //     },
              //     {
              //       _name: "CSidebarNavItem",
              //       name: "อะคาเดมี่ 3",
              //       to: "/academy/academy-03",
              //       icon: "cil-file",
              //       permission: 25,
              //     },
              //     {
              //       _name: "CSidebarNavItem",
              //       name: "อะคาเดมี่ 4",
              //       to: "/academy/academy-04",
              //       icon: "cil-file",
              //       permission: 26,
              //     },
              //   ],
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการอะคาเดมี่",
              //   to: "/academy",
              //   icon: "cil-image",
              //   permission: 61,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการหัวข้ออะคาเดมี่",
              //   to: "/academy-topic",
              //   icon: "cil-image",
              //   permission: 62,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "ประชาสัมพันธ์",
              //   to: "/notify-improvements",
              //   icon: "cil-comment-bubble",
              //   permission: 63,
              // },
            ],
          },

          // {
          //   id: 11,
          //   _name: "CSidebarNavDropdown",
          //   name: "ตั้งค่า (ลูกค้าบริษัท)",
          //   icon: "cil-user",
          //   permission: 11,
          //   _children: [
          //     // {
          //     //   id: 1,
          //     //   _name: "CSidebarNavItem",
          //     //   name: "วิธีการสมัคร",
          //     //   to: "/register",
          //     //   icon: "cil-file",
          //     //   permission: 12,
          //     // },
          //     {
          //       id: 1,
          //       _name: "CSidebarNavItem",
          //       name: "ข้อกำหนดและเงื่อนไข",
          //       to: "/term-and-condition-partner",
          //       icon: "cil-file",
          //       permission: 13,
          //     },
          //     {
          //       id: 1,
          //       _name: "CSidebarNavItem",
          //       name: "นโยบายข้อมูลส่วนบุคคล",
          //       to: "/privacy-policy-partner",
          //       icon: "cil-file",
          //       permission: 14,
          //     },
          //     // {
          //     //   id: 20,
          //     //   _name: "CSidebarNavItem",
          //     //   name: "คำถามที่พบบ่อย",
          //     //   to: "/faq",
          //     //   icon: "cil-comment-bubble",
          //     //   permission: 21,
          //     // },
          //     {
          //       id: 20,
          //       _name: "CSidebarNavItem",
          //       name: "คำถามที่พบบ่อย",
          //       to: "/faq/partner",
          //       icon: "cil-comment-bubble",
          //       permission: 15,
          //     },
          //   ],
          // },
          {
            id: 16,
            _name: "CSidebarNavDropdown",
            name: " ตั้งค่า (ลูกค้าทั่วไป)",
            icon: "cil-user",
            permission: 16,
            _children: [
              {
                id: 1,
                _name: "CSidebarNavItem",
                name: "ข้อกำหนดและเงื่อนไข",
                to: "/term-and-condition-member",
                icon: "cil-file",
                permission: 58,
              },
              {
                id: 1,
                _name: "CSidebarNavItem",
                name: "นโยบายข้อมูลส่วนบุคคล",
                to: "/privacy-policy-member",
                icon: "cil-file",
                permission: 17,
              },
              {
                id: 20,
                _name: "CSidebarNavItem",
                name: "คำถามที่พบบ่อย",
                to: "/faq/member",
                icon: "cil-comment-bubble",
                permission: 18,
              },
            ],
          },
          // {
          //   id: 2,
          //   _name: "CSidebarNavDropdown",
          //   name: "เกี่ยวกับนักขายออนไลน์",
          //   icon: "cil-cash",
          //   permission: 2,
          //   _children: [
          //     {
          //       id: 1,
          //       _name: "CSidebarNavItem",
          //       name: "ข้อตกลงและเงื่อนไขการใช้งาน",
          //       to: "/term-and-condition",
          //       icon: "cil-file",
          //       permission: 9,
          //     },
          //   ],
          // },

          // {
          //   id: 2,
          //   _name: "CSidebarNavDropdown",
          //   name: "เกี่ยวกับการเงิน",
          //   icon: "cil-dollar",
          //   permission: 33,
          //   _children: [
          //     {
          //       _name: "CSidebarNavItem",
          //       name: "คำขอถอนเงิน",
          //       to: "/withdraw",
          //       icon: "cil-dollar",
          //       permission: 34,
          //     },
          //     {
          //       _name: "CSidebarNavItem",
          //       name: "รายการการเงินแบรนด์",
          //       to: "/finance",
          //       icon: "cil-dollar",
          //       permission: 35,
          //     },
          //     {
          //       _name: "CSidebarNavItem",
          //       name: "รายการค้างชำระแบรนด์",
          //       to: "/overdue-partner",
          //       icon: "cil-dollar",
          //       permission: 57,
          //     },
          //     {
          //       _name: "CSidebarNavItem",
          //       name: "รายการคะแนนในระบบ",
          //       to: "/point",
          //       icon: "cil-dollar",
          //       permission: 55,
          //     },
          //     {
          //       _name: "CSidebarNavItem",
          //       name: "รายการเงินคืนที่ได้รับ",
          //       to: "/cashback",
          //       icon: "cil-dollar",
          //       permission: 56,
          //     },
          //   ],
          // },
          // {
          //   id: 2,
          //   _name: "CSidebarNavDropdown",
          //   name: "จัดการสินค้า",
          //   icon: "cil-window-maximize",
          //   permission: 2,
          //   _children: [
          //   ],
          // },
          // {
          //   id: 2,
          //   _name: "CSidebarNavDropdown",
          //   name: "Static Page",
          //   icon: "cil-window-maximize",
          //   permission: 2,
          //   _children: [
          //     {
          //       _name: "CSidebarNavItem",
          //       name: "จัดการเว็บไซต์",
          //       to: "/section",
          //       icon: "cil-window-maximize",
          //       permission: 5,
          //     },

          //     // {
          //     //   id: 1,
          //     //   _name: "CSidebarNavItem",
          //     //   name: "นโยบายความเป็นส่วนตัว",
          //     //   to: "/privacy-policy",
          //     //   icon: "cil-file",
          //     //   permission: 10,
          //     // },
          //     // {
          //     //   id: 1,
          //     //   _name: "CSidebarNavItem",
          //     //   name: "เงื่อนไขและข้อกำหนด",
          //     //   to: "/term-and-condition-member",
          //     //   icon: "cil-file",
          //     //   permission: 58,
          //     // },

          //   ],
          // },
          // {
          //   _name: "CSidebarNavItem",
          //   name: "รายการแบรนด์",
          //   to: "/partner",
          //   icon: "cil-people",
          //   permission: 37,
          // },

          // {
          //   _name: "CSidebarNavItem",
          //   name: "รายการอัพเกรดรอยืนยัน",
          //   to: "/member-wait-approve",
          //   icon: "cil-people",
          //   permission: 39,
          // },

          {
            id: 40,
            _name: "CSidebarNavDropdown",
            name: "ตั้งค่าขนส่ง",
            icon: "cil-settings",
            permission: 40,
            _children: [
              {
                _name: "CSidebarNavItem",
                name: "ตั้งค่าการจัดส่ง",
                to: "/shipping-setting",
                icon: "cil-settings",
                permission: 47,
              },
              {
                _name: "CSidebarNavItem",
                name: "ตั้งค่าขนส่งภายในประเทศ",
                to: "/shipping-setting/thai",
                icon: "cil-settings",
                permission: 47,
              },
              {
                _name: "CSidebarNavItem",
                name: "ตั้งค่าขนส่งต่างประเทศ",
                to: "/shipping-setting/abroad",
                icon: "cil-settings",
                permission: 47,
              },
            ],
          },
          {
            id: 40,
            _name: "CSidebarNavDropdown",
            name: "เกี่ยวกับระบบ",
            icon: "cil-settings",
            permission: 40,
            _children: [
              {
                _name: "CSidebarNavItem",
                name: "ตั้งค่า Plugin",
                to: "/setting-plugins",
                icon: "cil-puzzle",
                permission: 83,
              },
              {
                _name: "CSidebarNavItem",
                name: "Log",
                to: "/logs",
                icon: "cil-list-rich",
                permission: 85,
              },
              {
                _name: "CSidebarNavItem",
                name: "รายการบัญชีรับเงินระบบ",
                to: "/bank",
                icon: "cil-money",
                permission: 36,
              },

              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการส่วนลด",
              //   to: "/voucher",
              //   icon: "cil-gift",
              //   permission: 43,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการแฟรชเซล",
              //   to: "/campaign",
              //   icon: "cil-list",
              //   permission: 44,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "รายการรอการตรวจสอบเพื่อเข้า แฟรชเซล",
              //   to: "/campaign/verify",
              //   icon: "cil-list",
              //   permission: 45,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "ตั้งค่าระบบ",
              //   to: "/settings",
              //   icon: "cil-settings",
              //   permission: 46,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "ตั้งค่าระบบการจัดส่ง",
              //   to: "/settings/cod",
              //   icon: "cil-settings",
              //   permission: 47,
              // },
              // {
              //   _name: "CSidebarNavItem",
              //   name: "ระบบปรับเปลี่ยนระดับผู้ใช้",
              //   to: "/settings/tier",
              //   icon: "cil-settings",
              //   permission: 48,
              // },
              {
                _name: "CSidebarNavItem",
                name: "รายการผู้ดูแลระบบ",
                to: "/admin",
                icon: "cil-people",
                permission: 49,
              },
              {
                _name: "CSidebarNavItem",
                name: "ตั้งค่า Tier",
                to: "/tier",
                icon: "cil-sitemap",
                permission: 74,
              },
              {
                _name: "CSidebarNavItem",
                name: "ตั้งค่าระบบ",
                to: "/setting",
                icon: "cil-settings",
                permission: 75,
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    sidebarItems() {
      return [
        {
          _name: "CSidebarNav",
          _children: this.filterMenuByPermission(this.sidebar._children),
        },
      ];
    },
  },
  created: async function () {
    await this.getPermissionList();
  },
  methods: {
    getPermissionList: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/permission`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.permissionList = resData.detail.permission;
      }
    },
    filterMenuByPermission(list) {
      var menus = this.filterPermission(list);
      menus.forEach((element) => {
        if (element._children) {
          element._children = this.filterPermission(element._children);
          element._children.forEach((element2) => {
            if (element2._children) {
              element2._children = this.filterPermission(element2._children);
            }
          });
        }
      });

      return menus;
    },
    filterPermission(list) {
      var t = list.filter((item) => {
        return this.permissionList.indexOf(item.permission) > -1;
      });
      return t;
    },
  },
};
</script>
